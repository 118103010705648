import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

const SliderMain = ({elem}) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [slideLength, setSlideLength] = useState(elem.elements?.length);
    const [onMouse, setOnMouse] = useState(false);
    const [timeOut, setTimeOut] = useState();

    useEffect(() => {
        if(!onMouse){
            setTimeOut(setTimeout(slideTimeout, 3000));
        }
    }, [activeSlide, onMouse])

    const setPrev = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(activeSlide <= 0){
            setActiveSlide(slideLength - 1)
        }else{
            setActiveSlide(activeSlide - 1)
        }
    }

    const slideTimeout = () => {
        if(activeSlide + 1 >= slideLength){
            setActiveSlide(0)
        }else{
            setActiveSlide(activeSlide + 1)
        }
    }

    const setNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(activeSlide + 1 >= slideLength){
            setActiveSlide(0)
        }else{
            setActiveSlide(activeSlide + 1)
        }
    }

    const handleEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOnMouse(true);
        clearTimeout(timeOut);
    }

    const handleLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOnMouse(false);
    }

  return (
    <div className='slider-wrapper' onMouseEnter={e => handleEnter(e)} onMouseLeave={e => handleLeave(e)}>
        <div className='slider-main' style={{left:`-${activeSlide * 100}%`}}>
            {elem.elements?.map((card, index) =>
                <div className="slider_area align-items-center slider1" key={index} 
                    style={{backgroundImage: `url(${card.front_image})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single_slider">
                                    <div className="slider_content">
                                        <div className="slider_text">
                                            <div className="slider_text_inner">
                                                <h1 style={{color: card.banner_font_color}} className='mb-20' dangerouslySetInnerHTML={{__html: card.title}}></h1>
                                                <h5 style={{color: card.banner_font_color}} dangerouslySetInnerHTML={{__html: card.description}}></h5>
                                            </div>
                                            {card.button_text && <div className="slider_button pt-5 d-flex">
                                                <div className="button">
                                                    <NavLink to={card.link}>{card.button_text} <i className="fa fa-long-arrow-right"></i></NavLink>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className='slider-nav'>
                <button className='slider-nav-button slider-nav-prev' onClick={e => setPrev(e)}>
                    <i className='fa fa-long-arrow-left'></i>
                </button>
                <button className='slider-nav-button slider-nav-next' onClick={e => setNext(e)}>
                    <i className='fa fa-long-arrow-right'></i>
                </button>
            </div>
    </div>
  )
}

export default SliderMain